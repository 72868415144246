import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
let mm = gsap.matchMedia();




//mm.add("(min-width: 480px)", () => {

var bigMenu = document.querySelector(".big-menu");
var menuBurger = document.querySelector(".menu-btn-link-fix");

let bigMenuOpenSocial = gsap.timeline({
   paused: true,
}).to(".big-menu__social-link", {
   delay: 0.2,
   ease: "power1.out",
   x: 0,
   duration: 0.6,
   opacity: 1,
}).addPause().to(".big-menu__social-link", {
   ease: "power1.out",
   duration: 0.1,
   opacity: 0,
}).addPause("exit");

let bigMenuOpenList = gsap.timeline({
   paused: true,
}).to(".big-menu__nav-link", {
   delay: 0.2,
   ease: "power1.out",
   x: 0,
   duration: 0.6,
   opacity: 1,
}).addPause().to(".big-menu__nav-link", {
   ease: "power1.out",
   duration: .1,
   opacity: 0,
}).addPause("exit");

let bigMenuHeaderFooter = gsap.timeline({
   paused: true,
}).to(".big-menu__header, .big-menu__footer", {
   delay: 0.9,
   ease: "power1.out",
   x: 0,
   duration: 0.8,
   opacity: 1,
}).addPause().to(".big-menu__header, .big-menu__footer", {
   ease: "power1.out",
   duration: 0.1,
   opacity: 0,
}).addPause("exit");


let bigMenuOpen = gsap.timeline({
   paused: true
}).to(bigMenu, {
   duration: 1.4,
   x: 0,
   ease: "power4.out",
}).addPause().to(bigMenu, {
   duration: .6,
   x: "100%",
   ease: "power4.out",
}).addPause("exit");
let exitTimeMenuOpen = "1.4";


let menuIcon = menuBurger.querySelector(".menu-btn-link-icon");
let menuIconClose = menuBurger.querySelector(".menu-btn__close");
let menuBurgerStatus = "close";


menuBurger.onclick = function () {
   if (menuBurgerStatus == "close") {
      bigMenuOpen.restart();
      bigMenuOpenList.restart();
      bigMenuOpenSocial.restart();
      bigMenuHeaderFooter.restart();

      //menuIcon.style.fill = '#fff';
      menuIcon.style.transform = "scale(0) rotate(180deg)";
      menuIconClose.style.transform = "scale(1) rotate(180deg)";

      mm.add("(max-width: 480px)", () => {
         document.body.style.overflow = 'hidden';
      })


      menuBurgerStatus = "open";
   } else {
      if (bigMenuOpen.time() < exitTimeMenuOpen) {
         bigMenuOpen.reverse();
         bigMenuOpenList.reverse();
         bigMenuOpenSocial.reverse();
         bigMenuHeaderFooter.reverse();
      } else {
         bigMenuOpen.play();
         bigMenuOpenList.play();
         bigMenuOpenSocial.play();
         bigMenuHeaderFooter.play();
      }

      //mm.add("(max-width: 480px)", () => {
      //   menuIcon.style.fill = '#121221';
      //});
      menuIcon.style.transform = "scale(1) rotate(0deg)";
      menuIconClose.style.transform = "scale(0) rotate(0deg)";

      mm.add("(max-width: 480px)", () => {
         document.body.style.overflow = 'auto';
      })

      menuBurgerStatus = "close";
   }
};